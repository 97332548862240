import { Container } from "../container";
import "./numbers.scss";
import helmet from "../../assets/icons/helmet.svg";
import like from "../../assets/icons/like.svg";
import painting from "../../assets/icons/painting.svg";
import pantone from "../../assets/icons/ic-pantone.svg";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { motion } from "framer-motion";

const sectionAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export const Numbers = () => {
  const { t } = useTranslation();

  return (
    <section className="numbers">
      <Container>
        <div className="numbers__content">
          <motion.ul
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.2, once: false }}
            className="numbers__list"
          >
            <motion.li custom={1}
                variants={sectionAnimation} className="numbers__item">
              <img src={like} alt="like" className="numbers__item-img" />
              <p className="numbers__item-number">
                <CountUp  duration={10} end={60} />%
              </p>
              <p className="numbers__item-text">{t("recommendation")}</p>
            </motion.li>
            <motion.li custom={2}
                variants={sectionAnimation} className="numbers__item">
              <img
                src={painting}
                alt="painting"
                className="numbers__item-img"
              />
              <p className="numbers__item-number">
                {" "}
                <CountUp duration={10} end={77} />+
              </p>
              <p className="numbers__item-text">{t("built")}</p>
            </motion.li>
            <motion.li custom={3}
                variants={sectionAnimation} className="numbers__item">
              <img src={helmet} alt="helmet" className="numbers__item-img" />
              <p className="numbers__item-number">
                <CountUp duration={10} end={48} />+
              </p>
              <p className="numbers__item-text">{t("specialist")}</p>
            </motion.li>
            <motion.li custom={4}
                variants={sectionAnimation} className="numbers__item">
              <img src={pantone} alt="pantone" className="numbers__item-img" />
              <p className="numbers__item-number">
                <CountUp duration={10} end={53} />+
              </p>
              <p className="numbers__item-text">{t("work")}</p>
            </motion.li>
          </motion.ul>
        </div>
      </Container>
    </section>
  );
};
