  export const language = [
  {
    code: "ru",
    name: "Russian",
    country_code: "ru",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "uz",
    name: "Uzbek",
    country_code: "uz",
  },
]