import { useRoutes } from "react-router-dom";
import { Main } from "../pages";
import { ContactUs } from "../pages";

const routes = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
  {
    path: "*",
    element: <Main />,
  },
];

export const Routes = () => {
  const elements = useRoutes(routes);
  return elements;
};
