import Intro from "../../components/intro/intro";
import { AboutUs, Benefits, Calculator, FAQ, Footer, Gallery, Header, HowWork, IntroSection, Numbers, ScrollIndicator, Services } from "../../components";
import Sidebar from "../../components/sidebar/sidebar";

export const Main = () => {
  return (
    <div id="outer-container">
      <Sidebar  pageWrapId={'page-wrap'} outerContainerId={'outer-container'}/>
      <div id="page-wrap">
        <Intro />
        <ScrollIndicator />
        <Header />
        <IntroSection/>
        <Numbers/>
        <HowWork/>
        <Services/>
        <AboutUs/>
        <Gallery/>
        <FAQ/>
        <Benefits/>
        <Calculator/>
        <Footer/>
      </div>
    </div>
  );
};
