export const works = [
  {
    id: 1,
    work: "work1"
  },
  {
    id: 2,
    work: "work2"
  },
  {
    id: 3,
    work: "work3"
  },
  {
    id: 4,
    work: "work4"
  },
  {
    id: 5,
    work: "work5"
  },
]