import { Container } from "../container"
import "./footer.scss"
import facebook from "../../assets/icons/facebook.svg"
import instagram from "../../assets/icons/instagram.svg"
import telegram from "../../assets/icons/telegram.svg"
import youtube from "../../assets/icons/youtube.svg"
import { useTranslation } from "react-i18next"

export const Footer = () => {
  const {t} = useTranslation()
  return (
    <section className="footer">
      <Container>
        <div className="footer__container">
          <ul className="footer__list">
            <li className="footer__item"><a target={"_blank"} rel="noreferrer" href="https://t.me/Gallery_manager"><img src={telegram} alt="social media icon" className="footer__icon" /></a></li>
            <li className="footer__item"><a target={"_blank"} rel="noreferrer" href="https://instagram.com/buildinvestuz?igshid=MzRlODBiNWFlZA=="><img src={instagram} alt="social media icon" className="footer__icon" /></a></li>
            <li className="footer__item"><a target={"_blank"} rel="noreferrer" href="https://instagram.com/buildinvestuz?igshid=MzRlODBiNWFlZA=="><img src={facebook} alt="social media icon" className="footer__icon" /></a></li>
            <li className="footer__item"><a target={"_blank"} rel="noreferrer" href="https://instagram.com/buildinvestuz?igshid=MzRlODBiNWFlZA=="><img src={youtube} alt="social media icon" className="footer__icon" /></a></li>
          </ul>
          <p className="footer__rights">{t("rights")}</p>
        </div>  
      </Container>
    </section>
  )
}