import { useTranslation } from "react-i18next";
import { Container } from "../container";
import "./visit-us.scss";
import phone from "../../assets/icons/telephone-fill.svg";
import buildings from "../../assets/icons/buildings.svg";
import envelope from "../../assets/icons/envelope.svg";
import { useRef } from "react";
import Swal from "sweetalert2";

export const VisitUs = () => {
  const { t } = useTranslation();

  const namevalue = useRef()
  const numberValue = useRef()

  const sendToTelegram = (e) => {
    e.preventDefault();

    fetch("https://site-to-tg.vercel.app/send/contact", {
      method: "POST",
      body: JSON.stringify({
        "name": namevalue.current.value,
        "number": numberValue.current.value,
        "id":  -819601869
    }),
      headers: {
        "Content-Type": "Application/json",
      },
    }).then(() => {
      console.log("success");
      Swal.fire(
        `${t("request_send")}`,
        `${t("request_success")}`,
        "success"
      );
    });;
  };

  return (
    <section className="visit-us">
      <Container>
        <div className="contact__form-wrapper d-flex align-items-center flex-column">
          <p className="text-center intro_contact-text">{t("leave_contact")}</p>
          <form onSubmit={sendToTelegram} className="contact__form row">
            <div className="col-sm-4">
              <input
                type="text"
                ref={namevalue}
                className="form-control"
                placeholder={t("name")}
                aria-label="Name"
                name="user_name"
                required
              />
            </div>
            <div className="col-sm my-sm-0 my-3">
              <input
                pattern="\w{0}"
                type="number"
                className="form-control"
                placeholder={t("phone")}
                aria-label="Number"
                name="user_phone"
                required
                ref={numberValue}
              />
            </div>
            <div className="col-sm">
              <button type="submit" className="w-100 btn d-block btn-primary">
                {t("form_btn")}
              </button>
            </div>
          </form>
        </div>
        <div className="visit-us__container">
          <ul className="visit-us__list">
            <li
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="visit-us__item"
            >
              <img src={buildings} alt="" className="visit-us__img" />
              <h3 className="visit-us__item-title">{t("visit-us")}</h3>
              <p className="visit-us__item-desc">{t("visit-us-text")}</p>
              <p className="visit-us__item-badge">{t("address")}</p>
            </li>
            <li
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="visit-us__item"
            >
              <img src={phone} alt="" className="visit-us__img" />
              <h3 className="visit-us__item-title">{t("call-us")}</h3>
              <p className="visit-us__item-desc">{t("call-us-text")}</p>
              <p className="visit-us__item-badge">
                <a href="tel:+998998904202">99 088 0088</a>
              </p>
            </li>
            <li
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="visit-us__item"
            >
              <img src={envelope} alt="" className="visit-us__img" />
              <h3 className="visit-us__item-title">{t("contact-email")}</h3>
              <p className="visit-us__item-desc">{t("contact-email-text")}</p>
              <p className="visit-us__item-badge">
                <a href="mailto:buildinvest@gmail.com">info@buildinvest.uz</a>
              </p>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
};
