import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./navbar.scss";
import { LiEl, LinkEl, NavLink, UlEl } from "./navbar.styled";

export const NavBarEl = () => {
  const { t } = useTranslation();
  return (
    <NavLink>
      <UlEl>
        <LiEl>
          <LinkEl href="#about-us" className="navbar__link">
            {t("about-us")}
          </LinkEl>
        </LiEl>
        <LiEl>
          <LinkEl href="#services" className="navbar__link">
            {t("services")}
          </LinkEl>
        </LiEl>
        <LiEl>
          <LinkEl href="#calculator" className="navbar__link">{t("calculator")}</LinkEl>
        </LiEl>
        <LiEl>
          <Link className="navbar__link-router" to={"contact-us"}>
            <LinkEl className="navbar__link">{t("contacts")}</LinkEl>
          </Link>
        </LiEl>
        <LiEl>
          <LinkEl href="#faq" className="navbar__link">
            FAQ
          </LinkEl>
        </LiEl>
      </UlEl>
    </NavLink>
  );
};
