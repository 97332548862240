import { useEffect, useState } from "react";
import {
  ProgressMainStyle,
  ProgressMainWrapper,
} from "./scroll-indicactor.styled";

export const ScrollIndicator = () => {
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const scrolled = (winScroll / height) * 100

    setScrollTop(scrolled)
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.addEventListener("scroll", onScroll)
  }, [])
  return (
    <div>
      <ProgressMainWrapper>
        <ProgressMainStyle style={{width: `${scrollTop}%`}} ></ProgressMainStyle>
      </ProgressMainWrapper>
    </div>
  );
};
