// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import { useLayoutEffect, useRef } from "react";
import "./gallery.scss";
// import img from "../../assets/images/calculatorbg.webp";
// import { motion } from "framer-motion";
// import { useTranslation } from "react-i18next";
// import intro1 from "../../assets/images/intro1.webp";
// import intro2 from "../../assets/images/intro2.webp";
// import intro3 from "../../assets/images/intro3.webp";
// import intro4 from "../../assets/images/intro4.webp";

// gsap.registerPlugin(ScrollTrigger);

// const sectionAnimation = {
//   hidden: {
//     x: -100,
//     opacity: 0,
//   },
//   visible: (custom) => ({
//     x: 0,
//     opacity: 1,
//     transition: { delay: custom * 0.3 },
//   }),
// };

// export const Gallery = () => {
//   const component = useRef();
//   const slider = useRef();

//   useLayoutEffect(() => {
//     let ctx = gsap.context(() => {
//       let panels = gsap.utils.toArray(".panel");
//       gsap.to(panels, {
//         xPercent: -100 * (panels.length - 1),
//         ease: "none",
//         scrollTrigger: {
//           trigger: slider.current,
//           pin: true,
//           scrub: 1,
//           snap: 1 / (panels.length - 1),
//           end: () => "+=" + slider.current.offsetWidth,
//           markers: false,
//         },
//       });
//     }, component);
//     return () => ctx.revert();
//   });

//   const { t } = useTranslation();
//   return (
//     <div
//       initial="hidden"
//       whileInView="visible"
//       viewport={{ amount: 0.2 }}
//       className="App"
//       ref={component}
//     >
//       {/* <div className="firstContainer">
//         <h1>Testing horizontal scrolling w/ three sections</h1>
//         <h2>First Container</h2>
//       </div> */}
//       <div ref={slider} className="container-g">
//         <div className="description panel blue">
//           <div>
//             <h2 className="gallery__title">{t("gallery")}</h2>
//             <div className="scroll-down">
//               <div className="arrow"></div>
//             </div>
//             <div className="gallery__img-wrapper">
//               <div className="gallery__square">
//                 <div>
//                   <img className="gallery__img1" src={intro1} alt="" />
//                 </div>
//               </div>
//               <div className="gallery__square">
//                 <div>
//                   <img className="gallery__img2" src={intro2} alt="" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="panel red">
//           {" "}
//           <motion.div variants={sectionAnimation} data-aos="flip-down">
//             <img src={https://w.forfun.com/fetch/b2/b206a27565dd7d1eee49b352ff6af959.jpeg
//         </div>
//         <div className="panel orange">TWO</div>
//         <div className="panel purple">THREE</div>
//       </div>
//       {/* <div className="lastContainer">Last Container</div> */}
//     </div>
//   );
// };

// import { useTranslation } from "react-i18next";

// export const Gallery = () => {
//   const { t } = useTranslation();
//   return (
//     <div className="gallery">
//       <h2 className="gallery__title">{t("gallery")}</h2>

//     </div>
//   );
// };

import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import project1 from "../../assets/images/project1.webp"
import project2 from "../../assets/images/project2.webp"
import project3 from "../../assets/images/project3.webp"
import project4 from "../../assets/images/project4.webp"
import project5 from "../../assets/images/project5.webp"
import project6 from "../../assets/images/project6.webp"

// import "./styles.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

export const Gallery = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="gallery">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2 swiper__loop"
      >
        <SwiperSlide className="swiper-slide-loop">
          <img src="https://buildinvest.netlify.app/static/media/intro3.7a8af581c720de7f160a.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src="https://buildinvest.netlify.app/static/media/intro4.37ed294125ba86d251a2.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src="https://buildinvest.netlify.app/static/media/intro1.f8ba3e253e722618d379.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src="https://buildinvest.netlify.app/static/media/intro2.2009bd221715e31d9cfe.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img className="swiper__loop-img" src={project3} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img className="swiper__loop-img" src={project4} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project1} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project2} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project5} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project6} alt="line" />
        </SwiperSlide>
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper1"
      >
        <SwiperSlide >
          <img src="https://buildinvest.netlify.app/static/media/intro3.7a8af581c720de7f160a.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide >
          <img src="https://buildinvest.netlify.app/static/media/intro4.37ed294125ba86d251a2.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide >
          <img src="https://buildinvest.netlify.app/static/media/intro1.f8ba3e253e722618d379.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide >
          <img src="https://buildinvest.netlify.app/static/media/intro2.2009bd221715e31d9cfe.webp" alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img className="swiper__loop-img" src={project3} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img className="swiper__loop-img" src={project4} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project1} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project2} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project5} alt="line" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-loop">
          <img src={project6} alt="line" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
