export const faq = [
  {
    id: 1,
    head: "faq-heading-0",
    body: "faq-body-0"
  },
  {
    id: 2,
    head: "faq-heading-1",
    body: "faq-body-1"
  },
  {
    id: 3,
    head: "faq-heading-2",
    body: "faq-body-2"
  },
  {
    id: 4,
    head: "faq-heading-3",
    body: "faq-body-3"
  },
  {
    id: 5,
    head: "faq-heading-4",
    body: "faq-body-4"
  },
  {
    id: 6,
    head: "faq-heading-5",
    body: "faq-body-5"
  },
  {
    id: 7,
    head: "faq-heading-6",
    body: "faq-body-6"
  },
]