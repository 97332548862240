export const aboutus = [
  {
    id: 1,
    work: "about-us1"
  },
  {
    id: 2,
    work: "about-us2"
  },
  {
    id: 3,
    work: "about-us3"
  },
  {
    id: 4,
    work: "about-us4"
  },
  {
    id: 5,
    work: "about-us5"
  },
]