import { useState } from "react";
import "./services.scss";
import { Container } from "../container";
import { useTranslation } from "react-i18next";

export const Services = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const { t } = useTranslation();

  return (
    <section id="services" className="services">
      <Container>
        <h2 className="services__title">{t("services")}</h2>
        <div className="services__tab-wrapper">
          <div className="services__tabs">
            <button
              className={
                toggleState === 1
                  ? "services__tab services__tab--construction services__tab-active"
                  : "services__tab services__tab--construction"
              }
              onClick={() => toggleTab(1)}
            >
              <span className="services__tab-text">{t("tab1")}</span>
            </button>
            <button
              className={
                toggleState === 2
                  ? "services__tab services__tab--interior services__tab-active"
                  : "services__tab services__tab--interior"
              }
              onClick={() => toggleTab(2)}
            >
              <span className="services__tab-text">{t("tab2")}</span>
            </button>
            <button
              className={
                toggleState === 3
                  ? "services__tab services__tab--project services__tab-active"
                  : "services__tab services__tab--project"
              }
              onClick={() => toggleTab(3)}
            >
              <span className="services__tab-text">{t("tab3")}</span>
            </button>
          </div>

          <div className="services__tab-contents">
            <div
              className={
                toggleState === 1 ? "services__tab-content  services__tab-content-active" : "services__tab-content"
              }
            >
              <h2 className="services__tab-title">{t("tab1")}</h2>
              <hr />
              <p>{t("tab-content1")}</p>
            </div>

            <div
              className={
                toggleState === 2 ? "services__tab-content  services__tab-content-active" : "services__tab-content"
              }
            >
              <h2 className="services__tab-title">{t("tab2")}</h2>
              <hr />
              <p>{t("tab-content2")}</p>
            </div>

            <div
              className={
                toggleState === 3 ? "services__tab-content  services__tab-content-active" : "services__tab-content"
              }
            >
              <h2 className="services__tab-title">{t("tab3")}</h2>
              <hr />
              <p>{t("tab-content3")} </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
