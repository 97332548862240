import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import intro1 from "../../assets/images/intro1.webp";
import intro2 from "../../assets/images/intro2.webp";
import intro3 from "../../assets/images/intro3.webp";
import intro4 from "../../assets/images/intro4.webp";
import { MButton } from "../button";
import { Container } from "../container";
import "./intro-section.scss";

const textAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: {delay: custom * 0.4}
  }),
};


export const IntroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  return (
    <motion.section viewport={0.1} initial="hidden" whileInView="visible" className="intro-section">
      <Container>
        <div className="intro-section__container">
          <div className="intro-section__content">
            <h1 className="visually-hidden">
            Building Dreams, Shaping Realities: Your Trusted Construction Partner
            </h1>
            <motion.p custom={1} variants={textAnimation} className="intro-section__text">{t("intro-section-text")}</motion.p>
            <motion.p custom={1} variants={textAnimation} className="intro-section__description">
              {t("intro-section-description")}
            </motion.p>
            <MButton onClick={() => navigate("/contact-us")} className="intro-section__consultatoin-btn">
              {t("consultation-button")}
            </MButton>
            <div className="intro-section__notification">
              <div className="intro-section__notification-container">
                <p className="intro-section__notification-text">
                  {t("notification-text")}
                </p>
              </div>
            </div>
          </div>
          <div className="intro-section__swiper-wrapper">
            <Swiper
              spaceBetween={30}
              effect={"fade"}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[EffectFade, Navigation, Pagination, Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={intro1} alt="design" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={intro2} alt="design" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={intro3} alt="design" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={intro4} alt="design" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
