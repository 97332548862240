import "./sidebar.scss";
import React from "react";
import { elastic as Menu } from "react-burger-menu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default props => {
  const { t } = useTranslation();
  return (
    <Menu right>
      <a className="menu-item" href="#about-us">
        {t("about-us")}
      </a>
      <a className="menu-item" href="#services">
        {t("services")}
      </a>
      <a className="menu-item" href="#calculator">
        {t("calculator")}
      </a>
      <Link className="menu-item" to={"/contact-us"}>
        {t("contacts")}
      </Link>
      <a className="menu-item" href="#faq">
        FAQ
      </a>

      <a
        className="site-header__number"
        data-back="."
        data-front="+99899 088 00 88"
        href="tel:+998990880088"
      >
        {" "}
      </a>
    </Menu>
  );
};
