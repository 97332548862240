import { styled } from "styled-components";
import { ulStyles, wrapperStyles } from "../../styles";

export const NavLink = styled.nav`
  ${wrapperStyles};
  @media only screen and (max-width: 680px) {
    display: none
  }
`;

export const UlEl = styled.ul`
  ${ulStyles}
  ${wrapperStyles};
  gap: 30px;
  cursor: pointer;
  @media only screen and (max-width: 970px) {
    gap: 20px;
  }
  @media only screen and (max-width: 800px) {
    gap: 10px;
  }
  @media only screen and (max-width: 695px) {
    gap: 8px;
  }
`;

export const LiEl = styled.li``;

export const LinkEl = styled.a`
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;

  @media only screen and (max-width: 900px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 735px) {
    font-size: 14px;
  }
`;
