import { styled } from "styled-components";

export const ProgressMainWrapper = styled.div`
  background: black;
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%
`

export const ProgressMainStyle = styled.div`
  height: 5px;
  background: #FF7A00;
  border-radius: 20px
`