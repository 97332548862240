
export const wrapperStyles = `
  display: flex;
  align-items: center;
  justify-content: center
`;

export const ulStyles = `
  list-style: none;
  margin: 0;
  padding: 0;
`