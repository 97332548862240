import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { aboutus } from "../../data/about-us";
import { Container } from "../container";
import "./about-us.scss";
import { motion } from "framer-motion";

const sectionAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const aboutAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export const AboutUs = () => {
  const { t } = useTranslation();

  // const [scroll, setScroll] = useState(false)

  // const changeOnScroll = () => {
  //   if (window.scrollY > 30) {
  //     setScroll(true)
  //   } else (
  //     setScroll(false)
  //   )
  // }

  // window.addEventListener("scroll", changeOnScroll)

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
      className="about-us"
      id="about-us"
    >
      <Container>
        <div className="about-us__container">
          <div className="about-us__text-wrapper">
            <motion.h2 variants={sectionAnimation} className="about-us__title">{t("about-us")}</motion.h2>
            <motion.ul   initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.2, once: false}} className="about-us__list">
              {aboutus.map((value, index) => (
                <motion.li   custom={index + 1}
                variants={aboutAnimation} className="about-us__item" key={value.id}>
                  <p className="about-us__item-text">{t(`${value.work}`)}</p>
                </motion.li>
              ))}
            </motion.ul>
          </div>
          <div className="about-us__slider-wrapper">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper swiper__wrapper"
            >
              <SwiperSlide>
                <div className="swiper__content">
                  <p className="swiper__more">{t("more")}</p>
                  <p className="swiper__number">15</p>
                  <p className="swiper__projects">{t("project1")}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper__content">
                  <p className="swiper__more">{t("more")}</p>
                  <p className="swiper__number">1000</p>
                  <p className="swiper__projects">{t("project2")}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper__content">
                  <p className="swiper__more">{t("more")}</p>
                  <p className="swiper__number">500</p>
                  <p className="swiper__projects">{t("project3")}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
