import { styled } from "styled-components";
import { wrapperStyles } from "../../styles";

export const ContentWrapper = styled.div`
  ${wrapperStyles};
  justify-content: space-between;
`;

export const LinkEl = styled.a`
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;

  @media only screen and (max-width: 900px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 720px) {
    font-size: 14px;
  }
`;