import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Container } from "../container";
import "./calculator.scss";

export const Calculator = () => {
  // state = { values: [50] };
  const { t } = useTranslation();
  const [data, setData] = useState(0);
  const [data1, setData1] = useState(0);

  const type = useRef();
  const duration = useRef();
  const m2 = useRef();
  const stairs = useRef();

  const sendToTelegram = (e) => {
    e.preventDefault();

    fetch("https://site-to-tg.vercel.app/send", {
      method: "POST",
      body: JSON.stringify({
        type: type.current.value,
        cont: duration.current.value,
        step: stairs.current.value,
        range: m2.current.value,
        id: -819601869,
      }),
      headers: {
        "Content-Type": "Application/json",
      },
    }).then(() => {
      console.log("success");
      Swal.fire(`${t("request_send")}`, `${t("request_success")}`, "success");
    });
  };

  return (
    <section id="calculator" className="calculator">
      <Container>
        <div className="calculator__content">
          <div className="calculator__form-wrapper">
            <h2 className="calulator__title">{t("project-calculator")}</h2>
            <form onSubmit={sendToTelegram}>
              <div className="calculator__form-content">
                <div>
                  <select
                    className="form-select calculator__select"
                    aria-label="Default select example"
                    ref={type}
                  >
                    <option defaultValue>{t("type")}</option>
                    <option value="1">{t("house")}</option>
                    <option value="2">{t("appartment")}</option>
                    <option value="3">{t("office")}</option>
                    <option value="4">{t("store")}</option>
                    <option value="5">{t("showroom")}</option>
                  </select>
                </div>
                <div>
                  <label className="d-block calculator__label" htmlFor="">
                    <input
                      type="text"
                      className="form-control calculator__input"
                      placeholder={t("project-duration")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      ref={duration}
                      required
                    />
                  </label>
                </div>

                <div>
                  <p className="calculator__value">{t("stair-number")}</p>
                  <input
                    type="range"
                    value={data}
                    step="1"
                    onChange={(e) => setData(e.target.value)}
                    name="amountRange"
                    min="1"
                    max="30"
                    className="calculator__range"
                    ref={stairs}
                  />
                  <p className="calculator__value-number">{data}</p>
                </div>
                <div>
                  <p className="calculator__value">{t("total-area")}</p>
                  <input
                    type="range"
                    value={data1}
                    step="1"
                    onChange={(e) => setData1(e.target.value)}
                    name="amountRange"
                    min="20"
                    max="1000"
                    className="calculator__range"
                    ref={m2}
                  />
                  <p className="calculator__value-number">{data1} m²</p>
                </div>
              </div>
              <div className="col-auto calculator__btn-wrapper">
                <button type="submit" className="btn btn-primary mt-5">
                  {t("form_btn")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};
