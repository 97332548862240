import { Link } from "react-router-dom"
import "./button.scss"
import { motion } from "framer-motion";
import { forwardRef } from "react";


export const Button = forwardRef(({children, onClick, className = "", to, type, ...props}, ref) => {
  if (to)
    return (
      <Link className={className + " button"} to={to} {...props}> 
        <span className="button__text">{children}</span>
      </Link>
    );
  return(
    <button type={type} className={className + " button"} {...props} onClick={onClick} ref={ref}>
      <span className="button__text">{children}</span>
    </button>
  )
})

export const MButton = motion(Button)