import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./how-work.scss";
import { works } from "../../data/works";
import { motion } from "framer-motion";

const sectionAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const featureAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export const HowWork = () => {
  const { t } = useTranslation();
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
      className="how-work"
    >
      <Container>
        <div className="how-work__container">
          <motion.h2 variants={sectionAnimation} className="how-work__title">
            {t("how-we-work")}
          </motion.h2>
          <motion.ul
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.2}}
            className="how-work__list"
          >
            {works.map((value, index) => (
              <motion.li
                custom={index + 1}
                variants={featureAnimation}
                key={index}
                className="how-work__item"
              >
                <p className="how-work__number">0{index + 1}</p>
                <p className="how-work__text"> {t(`${value.work}`)}</p>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </Container>
    </motion.section>
  );
};
