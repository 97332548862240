import { useState } from "react";
import { useTranslation } from "react-i18next";
import { faq } from "../../data";
import { Container } from "../container";
import "./faq.scss";

export const FAQ = () => {
  const { t } = useTranslation();

  const [clicked, setClicked] = useState(false);

  const toggle = (id) => {
    if (clicked === id) {
      // if clicked question is already active,then close it
      return setClicked(null);
    }

    setClicked(id);
  };

  return (
    <section id="faq" className="faq">
      <Container>
        <h2 className="faq__title">FAQ</h2>
        <ul className="faq__list">
          {faq.map((item) => {
            return (
              <li key={item.id} className="faq__item">
                <div
                  className="faq__item-btn-wrapper"
                  onClick={() => toggle(item.id)}
                  
                >
                  <h3 className="faq__item-title">{t(`${item.head}`)}?</h3>
                  <button
                    className={
                      clicked === item.id
                        ? "faq__btn faq__btn-opened"
                        : "faq__btn"
                    }
                  ></button>
                </div>
                <p
                  className={
                    clicked === item.id
                      ? "faq__item-text faq__item-text--opened"
                      : "faq__item-text"
                  }
                >
                  {t(`${item.body}`)}
                </p>
              </li>
            );
          })}
        </ul>
        {/* <p>{t("faq-heading-0")}</p>
            <div className="faq__body">
              <p className="faq__body-text">{t("faq-body-0")}</p>
            </div>
            <p>{t("faq-heading-1")}</p>
            <div className="faq__body">
              <p className="faq__body-text">{t("faq-body-1")}</p>
            </div>
            <p>{t("faq-heading-2")}</p>
            <div className="faq__body">
              <p className="faq__body-text">{t("faq-body-2")}</p>
            </div>
            <p>{t("faq-heading-3")}</p>
            <div className="faq__body">
              <p className="faq__body-text">{t("faq-body-3")}</p>
            </div>
            <p>{t("faq-heading-4")}</p>
            <div className="faq__body">
              <p className="faq__body-text">{t("faq-body-4")}</p>
            </div>
            <p>{t("faq-heading-5")}</p>
            <div className="faq__body">
              <p className="faq__body-text">{t("faq-body-5")}</p>
            </div>
            <p>{t("faq-heading-6")}</p>
            <div className="faq__body">
              <p className="faq__body-text">{t("faq-body-6")}</p>
            </div> */}
            
      </Container>

      
    </section>
  );
};
