import "./sidebar.scss";
import React from "react";
import { elastic as Menu } from "react-burger-menu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default props => {
  const { t } = useTranslation();
  return (
    <Menu right>
      <Link className="menu-item"  to={"/"}>
        {t("about-us")}
      </Link>
      <Link className="menu-item"  to={"/"}>
        {t("services")}
      </Link>
      <Link className="menu-item"  to={"/"}>
        {t("calculator")}
      </Link>
      <Link className="menu-item" to={"contact-us"}>
        {t("contacts")}
      </Link>
      <Link className="menu-item"  to={"/"}>
        FAQ
      </Link>

      <Link
        className="site-header__number"
        data-back="."
        data-front="+99899 088 00 88"
        href="tel:+998990880088"
      >
        {" "}
      </Link>
    </Menu>
  );
};
