import "./benefits.scss"
import { useTranslation } from "react-i18next"
import { Container } from "../container"

export const Benefits = () => {
  const {t} = useTranslation()
  return (
    <section className="benefits">
      <Container>
        <div className="benefits__content">
          <h2 data-aos="fade-down" className="benefits__title">{t("benefits-title")}</h2>
          <ul className="benefits__list">
            <li className="benefits__item">
              <div data-aos="fade-right" className="benefits__text-wrapper">
                <h3 className="benefits__item-title">{t("benefit-title1")}</h3>
                <p className="benefits__text">{t("benefit-text1")}</p>
              </div>
              <img data-aos="flip-down" src="https://phonoteka.org/uploads/posts/2023-03/1679825197_phonoteka-org-p-okhrana-truda-oboi-instagram-1.jpg" alt="build invest" className="benefits__img" />
            </li>
            <li className="benefits__item">
              <div data-aos="fade-right" className="benefits__text-wrapper">
                <h3 className="benefits__item-title">{t("benefit-title2")}</h3>
                <p className="benefits__text benefits__text--cost">{t("benefit-text2")}</p>
              </div>
              <img data-aos="flip-down" src="https://cdn.dribbble.com/users/1601883/screenshots/3547735/first_scene_blue_800x600_2_dribbble.gif" alt="build invest" className="benefits__img-quality" />
            </li>
            <li className="benefits__item">
              <div data-aos="fade-right" className="benefits__text-wrapper">
                <h3 className="benefits__item-title">{t("benefit-title3")}</h3>
                <p className="benefits__text">{t("benefit-text3")}</p>
              </div>
              <img data-aos="flip-down" src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/c1a1ac72969113.5bf9ecdda8e4c.gif" alt="build invest" className="benefits__img-quality" />
            </li>
          </ul>
        </div>
      </Container>
    </section>
  )
}

// https://avatars.mds.yandex.net/i?id=3bb08fe0711a617521943be58ff85d582b85dfa9-4600463-images-thumbs&n=13