import { TypeAnimation } from "react-type-animation";
import "./intro.scss"

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

 const Intro = () => {
  const [visible, setVisible] = useState(true);
  const {t} = useTranslation()

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 3000);
  }, []);

  return visible ? (
    <div className="type-animation type-animation__wrapper">
      <TypeAnimation
        sequence={[`${t("intro-text")}`, 1000]}
        wrapper="span"
        speed={10}
        repeat={Intro}
        className="type-animation__text"
      />
    </div>
  ) : (
    <div />
  );
};

export default Intro
